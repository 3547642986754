"use client";

import React, { HTMLAttributes, useState } from "react";

import Link from "next/link";
import { cn, safeUrl } from "@/lib/utils";

import { UserPuzzleHistory } from "@prisma/client";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "./ui/table";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "./ui/select";

interface LeaderboardProps {
  leaderboard: UserPuzzleHistory[];
  showHeader: boolean;
}

const LeaderboardTable: React.FC<
  LeaderboardProps & HTMLAttributes<HTMLDivElement>
> = ({ leaderboard, showHeader }) => {
  return (
    <div>
      <Table className="mt-4">
        {showHeader == true && (
          <TableHeader className="h-[30px] border-b-4 border-primary bg-[#f7f5ff] text-[#3f3f3f]">
            <TableRow>
              <TableHead className="h-10"></TableHead>
              <TableHead className="h-10">Kullanıcı adı</TableHead>
              <TableHead className="h-10 text-right">Puan</TableHead>
            </TableRow>
          </TableHeader>
        )}
        <TableBody>
          {leaderboard &&
            leaderboard.map((rank: any, index: number) => (
              <TableRow key={index}>
                <TableCell className="pb-2 pt-2">
                  {index == 0 && (
                    <div className="w-5 rounded-xl border border-[#f3de71] bg-[#fcfaf1] text-center text-[#e9c300]">
                      <span>{index + 1}</span>
                    </div>
                  )}
                  {index == 1 && (
                    <div className="w-5 rounded-xl border border-[#9e9e9e] bg-[#eeeeee] text-center text-[#9e9e9e]">
                      <span>{index + 1}</span>
                    </div>
                  )}
                  {index == 2 && (
                    <div className="w-5 rounded-xl border border-[#ffac35] bg-[#fff2df] text-center text-[#ffac35]">
                      <span>{index + 1}</span>
                    </div>
                  )}
                  {index != 0 && index != 1 && index != 2 && (
                    <div className="w-5 text-center text-[#7e7e7e]">
                      <span>{index + 1}</span>
                    </div>
                  )}
                </TableCell>
                <TableCell className="pb-2 pt-2">
                  {rank.UserLoginData.NickName}
                </TableCell>
                <TableCell className="pb-2 pt-2 text-right">
                  {rank.Rating}
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </div>
  );
};

export default LeaderboardTable;
