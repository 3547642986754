"use client";

import React, { HTMLAttributes } from "react";
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselPrevious,
  CarouselNext,
} from "./ui/carousel";
import Autoplay from "embla-carousel-autoplay";
import Link from "next/link";
import { cn, safeUrl } from "@/lib/utils";
import Image from "next/image";
import { Banners } from "@prisma/client";

export type Banner = Banners;

interface BannerCarouselProps {
  banners: Banner[];
}

const BannerCarousel: React.FC<
  BannerCarouselProps & HTMLAttributes<HTMLDivElement>
> = ({ banners, className }) => {
  return (
    <div className={cn(className)}>
      <Carousel
        className="pb-[3.75rem]"
        opts={{ loop: true }}
        plugins={[
          Autoplay({
            delay: 6000,
            stopOnMouseEnter: true,
            stopOnInteraction: true,
          }),
        ]}
      >
        <CarouselContent>
          {banners.map(({ Url, ImageUrl, Text }, index) => (
            <CarouselItem key={`banner-carousel-${ImageUrl}-${index}`}>
              <Link
                href={Url ?? "#!"}
                className="relative block h-[23.125rem] w-full no-underline"
              >
                {ImageUrl && (
                  <Image
                    src={safeUrl(ImageUrl) ?? ""}
                    alt={Text ?? ""}
                    fill={true}
                    sizes="auto"
                    priority={true}
                    className="absolute inset-0 h-full w-full object-cover"
                  />
                )}
                {Text ? (
                  <div
                    className="prose absolute bottom-0 left-0 right-0 max-h-full w-96 max-w-full overflow-auto rounded bg-neutral-0 bg-opacity-80 p-4 sm:bottom-4 sm:left-4 sm:right-auto"
                    dangerouslySetInnerHTML={{
                      __html: Text,
                    }}
                  ></div>
                ) : null}
              </Link>
            </CarouselItem>
          ))}
        </CarouselContent>
        <CarouselPrevious
          variant={"text"}
          className="bottom-0 left-auto right-14 top-auto translate-y-0"
        />
        <CarouselNext
          variant={"default"}
          className="bottom-0 left-auto right-0 top-auto translate-y-0"
        />
      </Carousel>
    </div>
  );
};

export default BannerCarousel;
